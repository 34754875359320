<template>
  <div class="logo-box">
    <router-link to="/">
      <img class="site-logo" title="Columbus" :src="colorLight? siteLogo : colorPrimary ? siteLogoPrimary : ''" />
    </router-link>
  </div>
</template>
<script>
export default {
  name: "LogoBox",
  props: {
    colorPrimary: false,
    colorLight: false,
  },
  data() {
    return {
      siteLogo: require("@/assets/img/logo-white-text.svg"),
      siteLogoPrimary: require("@/assets/img/logo-primary-text.svg"),
    };
  },
};
</script>
<style lang="scss" scoped>
.logo-box {
  max-width: 200px;
  display: block;
  padding-bottom: 3px;
  position: relative;
  @media screen and (max-width: 768px){
    max-width: 164px;
  }
  a {
    display: block;
  }
  &::after {
    content: '';
    height: 4px;
    background-color: currentColor;
    width: 62%;
    position: absolute;
    left: 0;
    bottom: 3px;
  }
}
</style>
<template>
  <div>
    <v-dialog v-model="dialog" class="contact-modal" max-width="500">
      <v-card>
        <v-card-title>
          <v-tabs
            v-model="selectedTab"
            class="mb-3 mt-3 tabs"
          >
            <v-tab value="demo">Schedule a demo</v-tab>
            <v-tab value="contact">Get in touch</v-tab>
          </v-tabs>

          <div class="d-flex align-center">
            <v-btn icon @click="closeDialog" class="btn-border">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <div class="modal-title">{{ selectedTab === 'demo' ? 'Schedule demo' : 'Get in touch' }}</div>
          </div>
        </v-card-title>

        <v-card-text>
          <v-form v-if="selectedTab === 'demo'" ref="demoForm" @submit.prevent="submitDemoForm">
            <p>Speak to a Columbus expert and learn how we can help your organisation.</p>

            <div class="d-flex">
              <v-text-field
                v-model="name"
                label="Name *"
                variant="outlined"
                clearable
                :rules="requiredField"
              ></v-text-field>

              <v-text-field
                v-model="lastName"
                label="Last name *"
                variant="outlined"
                class="ml-3"
                clearable
                :rules="requiredField"
              ></v-text-field>
            </div>

            <v-text-field
              v-model="email"
              label="Business email *"
              variant="outlined"
              clearable
              full-width
              :rules="emailRules"
            ></v-text-field>

            <div class="d-flex">
              <v-text-field
                v-model="jobTitle"
                label="Job title *"
                variant="outlined"
                clearable
                :rules="requiredField"
              ></v-text-field>

              <v-text-field
                v-model="companyName"
                label="Company name *"
                variant="outlined"
                class="ml-3"
                clearable
                :rules="requiredField"
              ></v-text-field>
            </div>

            <v-textarea
              v-model="message"
              label="Are there any pain points you’re looking to address with Columbus?"
              variant="outlined"
              clearable
              full-width
            ></v-textarea>

            <div>Do you already have a Strategy & OKR tool at your organisation?</div>

            <v-radio-group v-model="alreadyUsedOkrSystem" inline>
              <v-radio label="No" value="no"></v-radio>
              <v-radio label="Yes" value="yes"></v-radio>
            </v-radio-group>

            <v-btn class="btn-primary mt-2" type="submit">Schedule demo</v-btn>
          </v-form>

          <v-form v-if="selectedTab === 'contact'" ref="contactForm" @submit.prevent="submitContactForm">
            <p>Contact our team to learn how Columbus can help you build stronger teams and drive growth.</p>

            <v-text-field
              v-model="nameContact"
              label="Name *"
              variant="outlined"
              clearable
              full-width
              :rules="requiredField"
            ></v-text-field>

            <v-text-field
              v-model="emailContact"
              label="Email address *"
              variant="outlined"
              clearable
              full-width
              :rules="emailRules"
            ></v-text-field>

            <v-textarea
              v-model="messageContact"
              label="Your message *"
              variant="outlined"
              clearable
              full-width
              :rules="requiredField"
            ></v-textarea>

            <div class="d-flex">
              <v-checkbox label="I agree to receiving marketing emails and news"></v-checkbox>
            </div>

            <v-btn class="btn-primary mt-2" type="submit">Send message</v-btn>
          </v-form>

          <div class="quote-box">
            <div class="d-flex align-center">
              <p>Kickstart Your Strategy: Let's Chart the Path Forward Together! 🚀</p>

              <div class="avatar-box"><img :src="sadhnaKanora" /></div>
            </div>

            <div class="quote-box__position">Sadhna Raj Kanoria | Head of Sales and Client Development</div>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="isSuccessSnackbarVisible" color="success" timeout="5000" location="top" right>
      Your request has been sent successfully
    </v-snackbar>

    <v-snackbar v-model="isErrorSnackbarVisible" color="error" timeout="5000" location="top" right>
      Please fill out all fields correctly
    </v-snackbar>
  </div>
</template>

<script>
import { eventBus } from '@/event-bus';
import axios from 'axios';

export default {
  data() {
    return {
      selectedTab: 'demo',
      dialog: false,
      isErrorSnackbarVisible: false,
      isSuccessSnackbarVisible: false,
      alreadyUsedOkrSystem: "",
      sadhnaKanora: require("@/assets/img/team/6.jpg"),

      // Schedule demo form
      name: '',
      lastName: '',
      email: '',
      message: '',
      jobTitle: '',
      companyName: '',
      
      // Contact form
      nameContact: '',
      emailContact: '',
      messageContact: '',
    };
  },
  created() {
    eventBus.value.$on('openFormModal', (value) => {
      this.selectedTab = value;
      this.dialog = true;
    });
  },
  computed: {
    requiredField() {
      return [(v) => v && v.length > 0 || 'Required field'];
    },
    emailRules() {
      return [
        (v) => !!v || 'Email is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ];
    },
  },

  // this.$root.$on('openGetInTouchModal', openModal);
  methods: {
    submitDemoForm() {
      this.$refs.demoForm.validate().then((isValid) => {
        if (isValid.valid) {
          axios.post(`https://prod-31.uksouth.logic.azure.com:443/workflows/ef6b46f76bdb4519b26732fc24307ead/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Qknoy11F4IRsh6P3019BuKirc2H8iZgAzmfQZ9uvWGw`, {
            subject: 'Schedule demo',
            body: `
              <div>
                <div><strong>Name: </strong>${this.name}</div>
                <div><strong>Email: </strong>${this.email}</div>
                <div><strong>Job Title: </strong>${this.jobTitle}</div>
                <div><strong>Company Name: </strong>${this.companyName}</div>
              </div><br>
              ${this.message}
              <br><br><hr />

              <div style="font-style: italic;">The message was sent from the Contact form 'Schedule demo' on the website</div>
            `,
          })
          .then(() => {
            this.isSuccessSnackbarVisible = true;
            this.closeDialog();
            this.$refs.demoForm.reset();
          })
          .catch((error) =>
          {
            console.error('Error:', error);
          });

        } else {
          this.isErrorSnackbarVisible = true;
        }
      })
    },
    submitContactForm() {
      this.$refs.contactForm.validate().then((isValid) => {
        if (isValid.valid) {
          axios.post(`https://prod-31.uksouth.logic.azure.com:443/workflows/ef6b46f76bdb4519b26732fc24307ead/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Qknoy11F4IRsh6P3019BuKirc2H8iZgAzmfQZ9uvWGw`, {
            subject: 'Contact form',
            body: `
              <div>
                <div><strong>Name: </strong>${this.nameContact}</div>
                <div><strong>Email: </strong>${this.emailContact}</div>
              </div><br>
              ${this.messageContact}
              <br><br><hr />

              <div style="font-style: italic;">The message was sent from the Contact form 'Get in touch' on the website</div>
            `,
          })
          .then(() => {
            this.isSuccessSnackbarVisible = true;
            this.closeDialog();
            this.$refs.contactForm.reset();
          })
          .catch((error) =>
          {
            console.error('Error:', error);
          });
        } else {
          this.isErrorSnackbarVisible = true;
        }
      })
    },
    closeDialog() {
      this.dialog = false;
    }
  }
};
</script>

<style lang="scss">
.contact-modal {
  justify-content: flex-end;
  align-items: unset;

  .tabs {
    button {
      text-transform: none;
    }
  }

  .v-overlay__scrim {
    opacity: 0.5;
    background: #0D0D0D;
  }

  .modal-title {
    font-family: 'Recoleta';
    font-size: 40px;
    margin-left: 16px;
    font-weight: bold;
    font-weight: 400;

    @media screen and (max-width: 768px) {
      font-size: 32px;
    }
  }

  .v-overlay__content {
    margin: 0;
    width: 100%;

    .v-card {
      color: #0D0D0D;
      border-radius: 0;
      min-height: 100vh;
      background: #B7DFBD;
    }
  }

  .quote-box {
    margin-top: 30px;
    margin-bottom: 20px;

    p {
      font-size: 16px;
      margin-bottom: 0;
    }

    img {
      width: 104px;
      min-width: 104px;
      margin-left: 15px;
      border-radius: 50%;
    }

    &__position {
      font-size: 14px;
      margin-top: 10px;
    }
  }
}
</style>

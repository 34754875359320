<template>
    <div class="main-section">
      <HeaderSite />
  
      <!-- Hero section -->
      <div class="hero-section">
        <button type="button" class="go-back-btn" @click.stop="windowHistory.go(-1)"><v-icon>mdi-arrow-left</v-icon>Go Back</button>
      </div>
  
      <!-- Content section -->
  
      <div class="content-section">
        <div class="content-section__box">
          <h1>Privacy Policy</h1>
            <p>Privacy Policy for Columbus Technologies Limited</p>
            <p>Introduction<br>Welcome to the website of Columbus Technologies Limited ("us", "we", "our"). This Privacy Policy outlines our policies and procedures on the collection, use, and disclosure of your information when you use our website located at www.columbus.tech and www.columbus.tech ("Website") and informs you about your privacy rights and how the law protects you.</p>
            <p class="mb-0">1. Information Collection and Use</p>
            <p>We collect several different types of information for various purposes to provide and improve our service to you. This includes:</p>
            <ul>
                <li><strong>Personal Data:</strong> While using our Website, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your email address, name, phone number, and postal address.</li>
                <li><strong>Usage Data:</strong> We may also collect information on how the Website is accessed and used. This Usage Data may include information such as your computer's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Website that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</li>
            </ul>
            <p class="mb-0">2. Cookies and Tracking Data</p>
            <p>We use cookies and similar tracking technologies to track the activity on our Website and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Website.</p>
            <p class="mb-0">3. Use of Data</p>
            <p>Columbus Technologies Limited uses the collected data for various purposes:</p>
            <ul>
                <li>To provide you with the products and services you request, such as scheduling a demo or sending you information about our software products, services, and resources</li>
                <li>To provide and maintain our Website</li>
                <li>To notify you about changes to our Website</li>
                <li>To allow you to participate in interactive features of our Website when you choose to do so</li>
                <li>To provide customer support</li>
                <li>To gather analysis or valuable information so that we can improve our Website</li>
                <li>To monitor the usage of our Website</li>
                <li>To detect, prevent, and address technical issues</li>
            </ul>
            <p class="mb-0">4. Transfer of Data</p>
            <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
            <p class="mb-0">5. Disclosure of Data</p>
            <p>We may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
            <ul>
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of Columbus Technologies Limited</li>
                <li>Prevent or investigate possible wrongdoing in connection with the Website</li>
                <li>Protect the personal safety of users of the Website or the public</li>
                <li>Protect against legal liability</li>
            </ul>
            <p class="mb-0">6. Security of Data</p>
            <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is completely secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
            <p class="mb-0">7. Your Data Protection Rights Under General Data Protection Regulation (GDPR)</p>
            <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Columbus Technologies Limited aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data. If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>
            <p class="mb-0">8. Changes to This Privacy Policy</p>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
            <p class="mb-0">Contact Us</p>
            <p>If you have any questions about this Privacy Policy or if you would like to exercise your data protection rights, please contact us at <a href="mailto:hello@columbus.tech">hello@columbus.tech</a>.</p>
            <p>By using our Website, you agree to the terms and conditions set out in this Privacy Policy. If you do not agree with any part of this Privacy Policy, please do not use our Website.</p>
            <p>Last Updated: 15th November 2023</p>    
        
        </div>
      </div>
  
      <FooterSite />
    </div>
  </template>
  
  <script>
  import HeaderSite from "@/components/Header";
  import FooterSite from "@/components/Footer";
  
  export default {
    name: "TermsAndConditions",
    components: {
      HeaderSite,
      FooterSite
    },
    data() {
      return {
        windowHistory: window.history
      };
    },
    head() {
      return {
        title: "Terms and Conditions",
        meta: [
          {
            hid: "description",
            name: "description",
            content:
              "Description of your AI-Powered Strategy Execution Platform.",
          },
        ],
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .hero-section {
    height: 512px;
    max-height: 80vh;
    background: #CDCDCD;
    position: relative;
    z-index: 1;
  }
  ul {
    padding-left: 1.15em;
    padding-bottom: 0.5em;
    font-size: 1.2em;
  }
  ul li {
    padding-bottom: 0.5em;
  }
  .go-back-btn {
    position: absolute;
    left: 16px;
    bottom: calc(35vh + 30px);
    color: #0442bf;
    font-size: 16px;
    display: flex;
    align-items: center;
    transition: color .3s ease-in-out;
    &:hover {
      color: #002e8a;
    }
    .v-icon {
      margin-right: 8px;
    }
  }
  .content-section {
    position: relative;
    z-index: 2;
    background: #F1F1F1;
    color: #22262E;
    padding-bottom: 50px;
    display: flex;
  
    &__box {
      display: inline-block;
      width: 90%;
      max-width: 870px;
      background: #FFFFFF;
      padding: 24px;
      margin: -35vh auto 0;
      border-radius: 6px;
      box-shadow: 0 0 20px #00000017;
    }
  }
  </style>
  
<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 25 24" fill="none">
    <path d="M5.66663 13H16.8366L11.9566 17.88C11.5666 18.27 11.5666 18.91 11.9566 19.3C12.3466 19.69 12.9766 19.69 13.3666 19.3L19.9566 12.71C20.3466 12.32 20.3466 11.69 19.9566 11.3L13.3766 4.69997C12.9866 4.30997 12.3566 4.30997 11.9666 4.69997C11.5766 5.08997 11.5766 5.71997 11.9666 6.10997L16.8366 11H5.66663C5.11663 11 4.66663 11.45 4.66663 12C4.66663 12.55 5.11663 13 5.66663 13Z" :fill=color />
  </svg>
</template>

<script>
export default {
  name: 'RightArrow',
  props: {
    color: {
      type: String,
      default: '#ED7A24'
    },
    size: {
      type: String,
      default: '25'
    }
  }
}
</script>
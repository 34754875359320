<template>
  <div class="review-section">
    <v-container>
      <v-row class="mt-10">
        <v-col cols="12" md="6">
          <div class="pre-quote">What our clients are saying</div>
          <div class="quote">Real results, real stories.</div>
        </v-col>

        <v-col cols="12" md="6" class="review-box">
    
          <div class="d-flex">
            <p>Columbus has transformed the way we approach strategy execution. We have used it to not only align our teams more effectively but also accelerate our decision-making process, moving from just planning strategies to implementing them with precision.</p>

            <div class="avatar-box"><img :src="reviewAvatar" /></div>
          </div>

          <div class="author">Alan Stenhouse | Chief Strategic Resource Officer at Plan b AI</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "UserReview",
  data() {
    return {
      reviewAvatar: require("@/assets/img/quote-avatar-alan.png"),
    };
  }
};
</script>

<style lang="scss" scoped>
.review-section {
  background: #FFF;
  color: #0D0D0D;
  padding-bottom: 60px;

  .pre-quote,
  .author {
    font-size: 14px;
  }

  .author {
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  .quote {
    font-family: 'Recoleta';
    font-size: 40px;
    font-weight: bold;

    @media screen and (max-width: 768px) {
      font-size: 32px;
    }
  }

  .review-box {
    .avatar-box {
      width: 104px;
      min-width: 104px;
      margin-left: 24px;

      @media screen and (max-width: 768px) {
        width: 56px;
        min-width: 56px;
        margin-left: 15px;
      }

      img {
        border-radius: 50%;
      }
    }
  }
}
</style>

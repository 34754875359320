<template>
  <nav class="aside-nav" :class="{'opened' : helpMobileMenuOpen}">
    <ul class="menu-list">
      <li v-for="item in menuList"
          class="menu-list__item"
          :key="item.id">
        <button type="button"
                class="tab-btn"
                @click.stop="changeMenuTab($event,`${item.menuId}`, `${item.menuTitle}`)"
                :class="{'active' : getSelectedTab === `${item.menuId}` }"
        ><span class="tab-btn-text">{{item.menuTitle}}</span>
          <v-icon v-if="item.submenu"
                class="tab-btn-icon"
                :class="{'active' : showSubmenu === `${item.menuTitle}` || getSelectedTab === `${item.menuTitle}`}"
                @click.stop="openSubmenu($event, `${item.menuTitle}`)">mdi-chevron-down</v-icon>
        </button>
        <ul class="submenu-list"
            v-show="showSubmenu === `${item.menuTitle}`">
          <li
              class="menu-list__item"
              v-for="submenuItem in item.submenu"
              :key="submenuItem.id">
            <button
                class="tab-btn"
                @click.stop="changeMenuTab($event, `${submenuItem.contentId}`, '')"
                :class="{'active' : getSelectedTab === `${submenuItem.contentId}`}"
            ><span class="tab-btn-text">{{submenuItem.title}}</span></button>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>
<script>
import {eventBus} from "@/event-bus";
export default {
  name: "HelpCenterMenu",
  data() {
    return {
      helpMobileMenuOpen: false,
      showSubmenu: '',
      showMenuTabContent: '',
      menuList: [
        {
          id: 1,
          menuTitle: 'OKR Feature- Training Module',
          menuId: 'trainingModule'
        },
        {
          id: 2,
          menuTitle: 'Basic of OKRs',
          menuId: 'basicOfOKRs',
          submenu: [
            {
              id: 1,
              title: 'Strategy Pyramid',
              contentId: 'strategyPyramid'
            },
            {
              id: 2,
              title: 'What are OKRs?',
              contentId: 'whatAreOKRs'
            },
            {
              id: 3,
              title: 'Why do we need OKRs?',
              contentId: 'whyDoWeNeedOKRs'
            }
          ],
        },
        {
          id: 3,
          menuTitle: 'Create Your OKRs',
          menuId: 'createYourOKRs',
          submenu: [
            {
              id: 1,
              title: 'Create an Objective',
              contentId: 'createAnObjective'
            },
            {
              id: 2,
              title: 'Establish Relationship for Objectives',
              contentId: 'establishRelationshipObjectives'
            },
            {
              id: 3,
              title: 'Create a Key Result',
              contentId: 'createKeyResult'
            },
            {
              id: 4,
              title: 'Establish Relationship for Key Results',
              contentId: 'establishRelationshipKeyResults'
            },
            {
              id: 5,
              title: 'Add Initiatives under Key Result',
              contentId: 'addInitiativesUnderKeyResult'
            },
            {
              id: 6,
              title: 'Review Publish OKRs',
              contentId: 'reviewPublishOKRs'
            },
          ],
        },
        {
          id: 4,
          menuTitle: 'Manage Your OKRs',
          menuId: 'manageYourOKRs',
          submenu: [
            {
              id: 1,
              title: 'Update Key Results',
              contentId: 'updateKeyResults'
            },
            {
              id: 2,
              title: 'View My OKRs',
              contentId: 'viewMyOKRs'
            },
            {
              id: 3,
              title: 'View My Team OKRs',
              contentId: 'viewMyTeamOKRs'
            },
            {
              id: 4,
              title: 'View Organisation OKRs',
              contentId: 'viewOrganisationOKRs'
            },
            {
              id: 5,
              title: 'Alignment View',
              contentId: 'alignmentView'
            }
          ],
        }
      ]
    };
  },
  mounted() {
    this.showActiveSubmenu()
  },
  methods: {
    changeMenuTab(event, val, title) {
      let item = event.target
      let activeMenuItem = document.body.querySelector('.menu-list__item.active')
      this.showMenuTabContent = val
      window.location.hash = `#${val}`
      this.helpMobileMenuOpen = false
      eventBus.value.$emit('showMenuTabContent', val);
      eventBus.value.$emit('helpMenuFromTab', this.helpMobileMenuOpen);
      if(title) {
        this.openSubmenu(event, title)
      }
      if(activeMenuItem) {
        if(item.closest('.menu-list__item').closest('.submenu-list')) {
          if(activeMenuItem !== item.closest('.menu-list__item').closest('.submenu-list').closest('.menu-list__item')){
            activeMenuItem.classList.remove('active')
          }
        } else {
          activeMenuItem.classList.remove('active')
        }
      }
    },
    openSubmenu(event, val) {
      let item = event.target
      let activeMenuItem = document.body.querySelector('.menu-list__item.active')
      if(this.showSubmenu === val) {
        this.showSubmenu = ''
      } else {
        this.showSubmenu = val
      }
      if(activeMenuItem) {
        activeMenuItem.classList.remove('active')
        if(activeMenuItem === item.closest('.menu-list__item')) {
          this.showSubmenu = ''
        }
      }
    },
    showActiveSubmenu() {
      let activeMenuBtn = document.body.querySelector('.tab-btn.active')
      if(activeMenuBtn) {
        if(activeMenuBtn.closest('.menu-list__item').closest('.submenu-list')) {
          activeMenuBtn.closest('.menu-list__item').closest('.submenu-list').closest('.menu-list__item').classList.add('active')
        } else {
          activeMenuBtn.closest('.menu-list__item').classList.add('active')
        }
      }
    },
  },
  created() {
    eventBus.value.$on('helpMenuFromHeader', (value) => {
      this.helpMobileMenuOpen = value
    });
  },
  computed: {
    getSelectedTab(){
      if (window.location.hash) {
        this.showMenuTabContent = window.location.href.split('#')[1]
      } else {
        this.showMenuTabContent = 'trainingModule'
      }
      return this.showMenuTabContent
    },
  },
};
</script>
<style lang="scss">
.aside-nav {
  .menu-list__item {
    &.active {
      > .tab-btn {
        .v-icon {
          transform: rotateX(180deg);
        }
      }
    }
  }
  .tab-btn {
    font-family: 'Campton', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.009375em;
    padding: 8px 8px 8px 12px;
    display: flex;
    align-items: center;
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 2px;
    box-shadow: none;
    &:hover,
    &.active {
      background-color: rgba(88, 85, 85, 0.58);
    }
    .tab-btn-text {
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
      text-align: left;
    }
  }
  .v-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: rgba(88, 85, 85, 0.58);
    }
    &.active {
      transform: rotateX(180deg);
    }
  }
}
</style>
<style lang="scss" scoped>
.aside-nav {
  flex: 0 0 300px;
  background-color: rgb(33, 33, 33);
  color: #ffff;
  padding: 24px 10px;
  &.opened {
    transform: translateX(0);
  }
  @media screen and (max-width: 1024px){
    transform: translateX(-120%);
    position: fixed;
    width: 100%;
    left: 0;
    top: 64px;
    bottom: 0;
    height: calc(100% - 64px);
    z-index: 11;
    transition: transform .4s ease-in-out;
  }
  a {
    color: #ffffff;
    transition: all .3s ease;
    &:hover {
      color: #0442BF;
    }
  }
  ul {
    list-style: none;
    .menu-list__item {
      &.active {
        .submenu-list {
          display: block!important;
        }
      }
    }
    .submenu-list {
      padding-left: 12px;
    }
  }
  .menu-list {
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(88, 85, 85, 0.58);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(88, 85, 85, 0.58);
      border-radius: 4px;
    }
  }
}
</style>
<template>
  <div class="main-section">
    <HeaderSite />

    <!-- Hero section -->
    <div class="page-hero-section">
      <div class="page-hero-section__title">
        <h1>Resources</h1>
        <p>Unlock the full potential of your business goals with our comprehensive OKR management tool.</p>
      </div>

      <video class="bg-video" autoplay loop muted playsinline disablePictureInPicture :poster="heroBgPoster">
        <source :src="heroBg" type="video/mp4" />
      </video>
    </div>

    <div class="all-posts">
      <v-container>
        <v-row class="mt-10">
          <v-col cols="12" md="4">
            <router-link to="/article/democratisation-of-corporate-decision-making"
              class="d-flex justify-space-between w-100">
              <v-card>
                <v-card-text>
                  <div class="title-text mt-3">
                    Democratisation of corporate decision-making: navigating the balance
                  </div>
                </v-card-text>

                <v-card-actions>
                  <div class="d-flex justify-space-between w-100">Find out more
                    <RightArrow color="#0D0D0D" />
                  </div>
                </v-card-actions>
              </v-card>
            </router-link>
          </v-col>

          <v-col cols="12" md="4">
            <router-link to="/article/driving-innovation-through-collaboration">
              <v-card>
                <v-card-text>
                  <div class="title-text mt-3">
                    Driving innovation through collaboration
                  </div>
                </v-card-text>

                <v-card-actions>
                  <div class="d-flex justify-space-between w-100">Find out more
                    <RightArrow color="#0D0D0D" />
                  </div>
                </v-card-actions>
              </v-card>
            </router-link>
          </v-col>

          <v-col cols="12" md="4">
            <router-link to="/article/making-better-leadership-decisions-with-ai">
              <v-card>
                <v-card-text>
                  <div class="title-text mt-3">
                    Making Better Leadership Decisions with AI
                  </div>
                </v-card-text>

                <v-card-actions>
                  <div class="d-flex justify-space-between w-100">Find out more
                    <RightArrow color="#0D0D0D" />
                  </div>
                </v-card-actions>
              </v-card>
            </router-link>
          </v-col>

          <v-col cols="12" md="4">
            <router-link to="/article/ai-driven-insights-for-sme-decision-making">
              <v-card>
                <v-card-text>
                  <div class="title-text mt-3">
                    AI-driven Insights for SME Decision-Making and Growth
                  </div>
                </v-card-text>

                <v-card-actions>
                  <div class="d-flex justify-space-between w-100">Find out more
                    <RightArrow color="#0D0D0D" />
                  </div>
                </v-card-actions>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Review section-->
    <UserReview />

    <FooterSite />
  </div>
</template>

<script>
import { videoMixin } from '@/plugins/videoMixin';
import HeaderSite from "@/components/Header";
import FooterSite from "@/components/Footer";
import UserReview from "@/components/UserReview";
import RightArrow from "@/icons/RightArrow";

export default {
  name: "Consultancy",
  mixins: [videoMixin],
  components: {
    HeaderSite,
    FooterSite,
    UserReview,
    RightArrow
  },
  data() {
    return {
      heroBg: require("@/assets/video/cronus-ai-bg.mp4"),
      heroBgPoster: require("@/assets/video/poster/cronus-ai-poster.jpg"),
    };
  },
  head() {
    return {
      title: "Consultancy",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Description of your AI-Powered Strategy Execution Platform.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.all-posts {
  background: #F1F1F1;
  color: #22262e;
  padding-bottom: 70px;

  .v-card {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    color: #0d0d0d;
    border: 0.5px solid #cedee4;
    box-shadow: 0 0 20px #00000012;
    border-radius: 6px;
    background: #fff;
    height: 100%;

    &:hover {
      .title-text {
        color: #0442BF;
      }
    }

    .v-card-actions {
      width: 100%;
      padding-left: 1rem;
      border-top: 1px solid #00000029;

      a {
        color: #0D0D0D;

        &:hover {
          color: #0D0D0D;
        }
      }
    }

    .title-text {
      font-family: "Recoleta";
      font-size: 22px;
      line-height: normal;
      font-weight: bold;
      transition: 350ms;
    }
  }
}</style>

<template>
  <div class="main-section">
    <HeaderSite />

    <!-- Hero section -->
    <div class="page-hero-section">
      <div class="page-hero-section__title">
        <h1>About us</h1>
        <p>Enabling organisations to achieve their most ambitious goals.</p>
      </div>

      <video class="bg-video" autoplay loop muted playsinline disablePictureInPicture :poster="heroBgPoster">
        <source :src="heroBg" type="video/mp4" />
      </video>
    </div>

    <!-- Get to know us -->
    <div class="get-to-know-us">
      <v-container>
        <h2 class="text-center">Get to know us</h2>

        <v-row class="mt-5 justify-space-between">
          <v-col cols="12" md="6">
            <img class="about-us-image" :src="aboutUs1" />

            <img class="absolute-img" :src="aboutUs2" />
          </v-col>

          <v-col cols="12" md="5">
            <p>Born from Columbus Tech’s 2020 vision, Columbus Digital is a leading provider of an AI-powered strategy execution platform and consultancy services 
              that drive organisational performance improvement through value generation, business agility, and AI-enabled intelligent decision-making. With Columbus, 
              organisations adopt new ways of working, empowering leadership to deliver on strategic outcomes while fostering an engaged and unified culture to manage change 
              and drive growth effectively. 
            </p>

            <p>Operating from Dubai, UAE, and Edinburgh, UK, Columbus enables organisations to achieve their most ambitious goals through shared purpose collectives and 
              data-driven insights. With a prestigious client roster, the Columbus team has collaborated with over 130 of the world’s highest-performing businesses, continuously 
              bringing the latest innovations and best practices to its clients.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Our team -->
    <div class="our-team d-none d-md-flex">
      <v-container>
        <h2 class="text-center">Our leadership team</h2>

        <v-row class="mt-10 text-center">
          <v-col cols="6" md="4">
            <div><img :src="teamAvatar1" class="team-avatar" /></div>
            <div class="team-name">Ian Bruce</div>
            <div class="team-position">Founder & CEO</div>
            <a href="https://www.linkedin.com/in/ian-bruce-838a7611/" target="_blank" class="social-link"><img
                :src="linkedinIcon" /></a>
          </v-col>

          <v-col cols="6" md="4">
            <div><img :src="teamAvatar2" class="team-avatar" /></div>
            <div class="team-name">Kat Gref</div>
            <div class="team-position">Founder & Managing Director</div>
            <a href="https://www.linkedin.com/in/kat-gref-2401784b/" target="_blank" class="social-link"><img
                :src="linkedinIcon" /></a>
          </v-col>

          <v-col cols="6" md="4">
            <div><img :src="teamAvatar3" class="team-avatar" /></div>
            <div class="team-name">Christopher Keith</div>
            <div class="team-position">Founder & Head of Development</div>
            <a href="https://www.linkedin.com/in/christopher-keith-37710417b/" target="_blank" class="social-link"><img
                :src="linkedinIcon" /></a>
          </v-col>
        </v-row>
        <v-row class="mt-10 text-center">
          <v-col md="2"></v-col>
          <v-col cols="6" md="4">
            <div><img :src="georgiSokolov" class="team-avatar" /></div>
            <div class="team-name">Georgi Sokolov</div>
            <div class="team-position">Chief Technology Officer</div>
            <a href="https://www.linkedin.com/in/jorosokolov/" target="_blank" class="social-link"><img
                :src="linkedinIcon" /></a>
          </v-col>

          <v-col cols="6" md="4">
            <div><img :src="sadhnaKanora" class="team-avatar" /></div>
            <div class="team-name">Sadhna Raj Kanoria</div>
            <div class="team-position">Head of Sales & Client Development</div>
            <a href="https://www.linkedin.com/in/sadhnarajk/" target="_blank" class="social-link"><img
                :src="linkedinIcon" /></a>
          </v-col>
          <v-col md="2"></v-col>
        </v-row>
      </v-container>
    </div>

    <div class="our-team d-sm-flex d-md-none">
      <v-container>
        <h2 class="text-center">Our leadership team</h2>

        <v-row class="mt-10">
          <v-col cols="6">
            <div><img :src="teamAvatar1" class="team-avatar" /></div>
            <div class="team-name">Ian Bruce</div>
            <div class="team-position">Founder & CEO</div>
            <a href="https://www.linkedin.com/in/ian-bruce-838a7611/" target="_blank" class="social-link"><img
                :src="linkedinIcon" /></a>
          </v-col>

          <v-col cols="6">
            <div><img :src="teamAvatar2" class="team-avatar" /></div>
            <div class="team-name">Kat Gref</div>
            <div class="team-position">Founder & Managing Director</div>
            <a href="https://www.linkedin.com/in/kat-gref-2401784b/" target="_blank" class="social-link"><img
                :src="linkedinIcon" /></a>
          </v-col>

          <v-col cols="6">
            <div><img :src="teamAvatar3" class="team-avatar" /></div>
            <div class="team-name">Christopher Keith</div>
            <div class="team-position">Founder & Head of Development</div>
            <a href="https://www.linkedin.com/in/christopher-keith-37710417b/" target="_blank" class="social-link"><img
                :src="linkedinIcon" /></a>
          </v-col>

          <v-col cols="6">
            <div><img :src="georgiSokolov" class="team-avatar" /></div>
            <div class="team-name">Georgi Sokolov</div>
            <div class="team-position">Chief Technology Officer</div>
            <a href="https://www.linkedin.com/in/jorosokolov/" target="_blank" class="social-link"><img
                :src="linkedinIcon" /></a>
          </v-col>

          <v-col cols="6">
            <div><img :src="sadhnaKanora" class="team-avatar" /></div>
            <div class="team-name">Sadhna Raj Kanoria</div>
            <div class="team-position">Head of Sales & Client Development</div>
            <a href="https://www.linkedin.com/in/sadhnarajk/" target="_blank" class="social-link"><img
                :src="linkedinIcon" /></a>
          </v-col>
          
        </v-row>
      </v-container>
    </div>

    <!-- Help and support -->
    <div class="help-support">
      <div class="bg-gradient" />

      <v-container>
        <h2>Help and support</h2>

        <v-row class="mt-10">
          <v-col cols="12" md="4">
            <v-card>
              <h5>Customer support</h5>
              <p>support@columbus.tech</p>

              <a href="mailto:support@columbus.tech" class="arrow-link">Contact now
                <RightArrow />
              </a>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card>
              <h5>Sales</h5>
              <p>sales@columbus.tech</p>

              <a href="mailto:sales@columbus.tech" class="arrow-link">Contact now
                <RightArrow />
              </a>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card>
              <h5>Talent and recuritment</h5>
              <p>careers@columbus.tech</p>

              <a href="mailto:careers@columbus.tech" class="arrow-link">Contact now
                <RightArrow />
              </a>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card>
              <h5>Dubai office</h5>
              <p>Jumeirah Living, Marina Gate, M Floor Dubai, United Arab Emirates</p>

              <a href="https://maps.app.goo.gl/d964d1i9e7dBeCos8" target="_blank" class="arrow-link">View in maps
                <RightArrow />
              </a>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card>
              <h5>UK office</h5>
              <p>93 George Street, Edinburgh EH2 3ES, United Kingdom</p>

              <a href="https://maps.app.goo.gl/pZ7iQ9gMKNVfsux28" target="_blank" class="arrow-link">View in maps
                <RightArrow />
              </a>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Review section-->
    <UserReview />

    <FooterSite />
  </div>
</template>

<script>
import { videoMixin } from '@/plugins/videoMixin';
import HeaderSite from "@/components/Header";
import FooterSite from "@/components/Footer";
import UserReview from "@/components/UserReview";
import RightArrow from "@/icons/RightArrow";

export default {
  name: "AboutUs",
  mixins: [videoMixin],
  components: {
    HeaderSite,
    FooterSite,
    UserReview,
    RightArrow
  },
  data() {
    return {
      heroBg: require("@/assets/video/cronus-ai-bg.mp4"),
      heroBgPoster: require("@/assets/video/poster/cronus-ai-poster.jpg"),
      linkedinIcon: require("@/assets/img/icons/linkedin.svg"),

      teamAvatar1: require("@/assets/img/team/1.jpg"),
      teamAvatar2: require("@/assets/img/team/2.jpg"),
      teamAvatar3: require("@/assets/img/team/3.jpg"),
      teamAvatar4: require("@/assets/img/team/4.jpg"),
      sadhnaKanora: require("@/assets/img/team/6.jpg"),
      georgiSokolov: require("@/assets/img/team/7.jpg"),

      aboutUs1: require("@/assets/img/about-us/1.jpg"),
      aboutUs2: require("@/assets/img/about-us/2.jpg"),
    };
  },
  head() {
    return {
      title: "AboutUs",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Description of your AI-Powered Strategy Execution Platform.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.get-to-know-us {
  background: #F1F1F1;
  color: #0D0D0D;
  padding: 30px;

  .v-col-12 {
    position: relative;
  }

  .about-us-image {
    width: 90%;
    border-radius: 19px;
    margin-bottom: 20px;
  }

  .absolute-img {
    position: absolute;
    bottom: 20px;
    right: 0;
    height: 60%;
    border-radius: 15px;
  }
}

.our-team {
  background: #F1F1F1;
  color: #0D0D0D;
  padding: 30px 0 80px;

  .team-name {
    font-family: 'Recoleta';
    font-size: 24px;
    font-weight: bold;
  }

  .social-link {
    width: 45px;
    height: 45px;
    margin: 10px auto 0;
  }

  .team-avatar {
    width: 104px;
    height: 104px;
    border-radius: 50%;
  }
}

.help-support {
  position: relative;
  padding: 50px 0;
  border-top: 1px solid #CDCDCD;
  background: url('@/assets/img/about-us/dubai-bg.png') center center;
  background-size: contain;

  .bg-gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: linear-gradient(107deg, #0442BF 6.73%, #0442BF 38.33%, rgba(4, 66, 191, 0.00) 80.15%);
  }

  h2 {
    position: relative;
    z-index: 10;
  }

  .v-card {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(45px);
    box-shadow: none;
    padding: 24px;
    z-index: 10;

    p {
      font-size: 18px;
      margin: 5px 0 16px;
    }
  }
}

.review-section {
  border-bottom: 1px solid #CDCDCD;
}</style>

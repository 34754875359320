<template>
  <div class="main-section">
    <HeaderSite />

    <!-- Hero section -->
    <div class="hero-section-post">
      <video class="bg-video" preload="none" autoplay loop muted playsinline disablePictureInPicture :poster="heroBgPoster">
        <source :src="aboutUsBg" type="video/mp4" />
      </video>

      <div class="hero-section-post__title">
        <div class="d-flex">
          <router-link to="/resources">
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="58" viewBox="0 0 38 58" fill="none">
              <path
                d="M33.568 29.5H4.31802M15.568 16L3.65901 27.909C2.78033 28.7877 2.78033 30.2123 3.65901 31.091L15.568 43"
                stroke="#111111" stroke-width="2" stroke-linecap="round" />
            </svg>
          </router-link>

          <div>
            <div class="w-100 d-flex mb-2">
              <div class="read-time"><img :src="speedometerIcon" /> 5 min read</div>
              <a v-if="articleData.newsLink" :href="articleData.newsLink" class="original-post" rel="nofollow">{{
                              articleData.newsName }}</a>
            </div>

            <h1>{{ articleData.title }}</h1>
          </div>

        </div>

      </div>
    </div>

    <!-- Content section -->
    <div class="content-section-post">
      <div class="content-section-post__box" v-html="articleData.content">
      </div>
    </div>

    <!-- Review section-->
    <UserReview />

    <FooterSite />
  </div>
</template>

<script>
import { videoMixin } from '@/plugins/videoMixin';
import HeaderSite from "@/components/Header";
import FooterSite from "@/components/Footer";
import UserReview from "@/components/UserReview";

export default {
  name: "Articles",
  mixins: [videoMixin],
  components: {
    HeaderSite,
    FooterSite,
    UserReview
  },
  data() {
    return {
      articleData: {
        title: "",
        content: "",
        metaTags: [],
        newsLink: "",
        newsName: ""
      },

      aboutUsBg: require("@/assets/video/about-us-bg.mp4"),
      heroBgPoster: require("@/assets/video/poster/about-us-poster.jpg"),
      speedometerIcon: require("@/assets/img/icons/speedometer.svg"),
    };
  },
  head() {
    return {
      title: "Terms and Conditions",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Description of your AI-Powered Strategy Execution Platform.",
        },
      ],
    };
  },
  beforeCreate() {
    import(`../db/${this.$route.params.name}.json`)
      .then(data => {
        this.articleData = data;

        document.title = this.articleData.title;

        const descriptionMeta = document.createElement("meta");
        descriptionMeta.name = "description";
        descriptionMeta.content = this.articleData.content.replace(/<[^>]+>|&[^;]+;/g, '').substring(0, 180);

        const existingDescriptionMeta = document.querySelector("meta[name='description']");
        if (existingDescriptionMeta) {
          existingDescriptionMeta.remove();
        }

        document.head.appendChild(descriptionMeta);
      })
      .catch(err => {
        this.$router.push('/404');
      });
  }
};
</script>

<style lang="scss">
.hero-section-post {
  position: relative;
  height: 512px;
  max-height: 80vh;
  background: #b8debe;
  display: flex;
  justify-content: center;
  overflow: hidden;

  video {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
  }

  &__title {
    width: 90%;
    max-width: 870px;
    position: absolute;
    bottom: 0;
    padding: 24px;
    color: #22262E;
    z-index: 10;
    border-radius: 3px 3px 0px 0px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(45px);

    h1 {
      font-family: 'Recoleta';

      @media screen and (max-width: 768px) {
        font-size: 22px;
      }
    }

    .original-post {
      margin-left: 15px;
    }

    .read-time {
      display: flex;
      color: #6F7680;

      img {
        padding-right: 3px;
      }
    }

    a {
      display: flex;
      align-items: center;
      margin-right: 15px;

      @media screen and (max-width: 768px) {
        margin-right: 0;
      }

      svg {
        width: 30px;
        opacity: 0.8;
        transition: 350ms;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.content-section-post {
  background: #F1F1F1;
  color: #22262E;
  padding-bottom: 50px;
  display: flex;

  &__box {
    display: inline-block;
    width: 90%;
    max-width: 870px;
    background: #FFFFFF;
    padding: 24px;
    margin: 0 auto;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 0 20px #00000017;

    p {
      font-size: 16px;
    }

    ul {
      font-size: 16px;
      margin-left: 20px;
      margin-bottom: 20px;

      li {
        margin-bottom: 7px;
      }
    }
  }
}</style>

<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "./assets/css/rewrite-vuetify.scss";
// @import "./assets/css/variable.scss";
@import "./assets/css/main.scss";
</style>

<template>
  <div>
    <v-app-bar app>
      <v-toolbar-title>
        <router-link to="/"><img class="site-logo" title="Columbus" :src="siteLogo" /></router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="help-center-link-box" v-if="helpPageContent">
        <button type="button"
                @click.stop="toggleMobileHelpMenu">
          Help center
        </button>
      </div>
      <div class="header-links">
        <router-link to="/consultancy">Consulting</router-link>
        <router-link to="/pricing">Pricing</router-link>
        <router-link to="/resources">Resources</router-link>
        <v-btn @click="openGetInTouchModal" class="btn-primary">Schedule demo</v-btn>
      </div>

      <v-app-bar-nav-icon @click="toggleMobileMenu" class="mobile-nav"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer class="mobile-nav" v-model="mobileMenuOpen" app>
      <v-list>
        <router-link to="/consultancy">
          <v-list-item link>
            <v-list-item-title>Consulting</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link to="/pricing">
          <v-list-item link>
            <v-list-item-title>Pricing</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link to="/resources">
          <v-list-item link>
            <v-list-item-title>Resources</v-list-item-title>
          </v-list-item>
        </router-link>
        <v-list-item>
          <v-btn @click="openGetInTouchModal" class="btn-primary">Schedule demo</v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <GetInTouchModal />
  </div>
</template>

<script>
import { eventBus } from '@/event-bus';
import GetInTouchModal from "@/components/GetInTouchModal";

export default {
  name: "HeaderSite",
  components: {
    GetInTouchModal
  },
  data() {
    return {
      siteLogo: require("@/assets/img/logo-white.svg"),

      mobileMenuOpen: false,
      helpMobileMenuOpen: false,
      helpPageContent: false,
    };
  },
  mounted() {
    let helpContent = document.body?.querySelector('.help-page')
    if(helpContent) {
      this.helpPageContent = true
    }
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
      if(this.helpMobileMenuOpen && this.mobileMenuOpen) {
        this.helpMobileMenuOpen = false
        eventBus.value.$emit('helpMenuFromHeader', this.helpMobileMenuOpen);
      }
    },
    toggleMobileHelpMenu() {
      this.helpMobileMenuOpen = !this.helpMobileMenuOpen;
      if(this.helpMobileMenuOpen && this.mobileMenuOpen) {
        this.mobileMenuOpen = false
      }
      eventBus.value.$emit('helpMenuFromHeader', this.helpMobileMenuOpen);
    },
    openGetInTouchModal() {
      eventBus.value.$emit('openFormModal', 'demo');
    },
  },
  created() {
    eventBus.value.$on('helpMenuFromTab', (value) => {
      this.helpMobileMenuOpen = value
    });
  },
};
</script>

<style lang="scss" scoped>
.site-logo {
  max-width: 100%;
  cursor: pointer;
  opacity: 1;
  transition: 350ms;

  &:hover {
    opacity: 0.7;
  }
}

.mobile-nav {
  a {
    color: #FFF;
  }
}
.help-center-link-box {
  @media screen and (min-width: 1025px) {
    display: none;
  }
  a {
    margin-left: 20px;
    transition: 350ms;
    color: #FFF;
    &:hover {
      color: #0442BF;
    }
  }
}
.header-links {
  display: flex;
  align-items: center;

  a {
    margin-left: 20px;
    transition: 350ms;
    color: #FFF;

    &:last-child {
      margin-right: 15px;
    }

    &:hover {
      color: #0442BF;
    }
  }

  button {
    margin-left: 20px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 768px) {
  .header-links {
    display: none
  }
}

@media screen and (min-width: 768px) {
  .mobile-nav {
    display: none
  }
}
</style>

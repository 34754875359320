export const videoMixin = {
  mounted() {
    this.$nextTick(() => {
      const videoElements = this.$el.querySelectorAll('video');

      videoElements.forEach((video) => {
        video.addEventListener('enterpictureinpicture', (event) => {
          event.preventDefault();
        });
      });
    });
  },
};
<template>
  <div class="main-section">
    <HeaderSite />

    <div class="parallax-video">
      <div class="bg-circle"></div>
      <div class="bg-opacity"></div>

      <video autoplay loop muted playsinline disablePictureInPicture :poster="heroVideoPoster">
        <source :src="heroVideo" type="video/mp4" />
      </video>

      <div class="hero-content">
        <h1 class="display-1 white--text mb-4">Next Gen AI-Powered Strategy Execution Platform​</h1>

        <v-btn class="btn-primary" @click="openGetInTouchModal('demo')">Schedule demo</v-btn>
        <v-btn class="contact-btn" @click="openGetInTouchModal('contact')">Get in touch</v-btn>
      </div>

      <div class="scroll-block">
        <div>Ambitious Goals and Clear Strategy: Align, Focus, Measure with OKRs & Business Observability</div>

        <div class="scroll-block__arrow" @click="scrollToNextBlock">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M11.0002 5V16.17L6.12021 11.29C5.73021 10.9 5.09021 10.9 4.70021 11.29C4.31021 11.68 4.31021 12.31 4.70021 12.7L11.2902 19.29C11.6802 19.68 12.3102 19.68 12.7002 19.29L19.2902 12.7C19.6802 12.31 19.6802 11.68 19.2902 11.29C18.9002 10.9 18.2702 10.9 17.8802 11.29L13.0002 16.17V5C13.0002 4.45 12.5502 4 12.0002 4C11.4502 4 11.0002 4.45 11.0002 5Z"
              fill="white" />
          </svg>
        </div>
      </div>
    </div>

    <!-- Strategy Execution section -->
    <div class="strategy-execution">
      <v-container>
        <h2>Strategy Management</h2>
        <p class="title-description text-center">
          Streamline, Align, and Communicate Organisation’s Long-Range Strategies​
        </p>

        <v-row class="mt-10">
          <v-col cols="12" md="4">
            <img :src="strategyExecutionIcon" loading="lazy" />

            <p>Dive into strategic themes, strategic objectives, and monitor progress through KPIs while adjusting to
              dynamics. As dynamics shift, adapt swiftly, ensuring you remain ahead of the curve - seizing opportunities
              and averting risks in real-time.​​</p>
          </v-col>

          <v-col cols="12" md="8" class="pr-md-0 overflow-hidden text-right">
            <img class="demo-img d-block" :src="strategyExecutionNew" loading="lazy" />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Business Observability section -->
    <div class="business-observability">
      <v-container>
        <h2>OKRs and Execution</h2>
        <p class="title-description text-center">
          Strategy Alignment & Execution Beyond the Boardroom
        </p>

        <v-row class="mt-10">
          <v-col cols="12" md="4">
            <img :src="businessObservabilityIcon" loading="lazy" />
            <p>
              Drive your organisation's direction by ensuring everyone, from top to bottom, shares the same vision.
            </p>

            <p>
              Set clear success metrics to maintain focus on priorities, enabling streamlined execution. With transparent
              progress tracking and clear goals, maximise your team's performance potential.
            </p>
          </v-col>

          <v-col cols="12" md="8" class="pr-md-0 text-right">
            <img class="demo-img" :src="businessObservabilityDemo" loading="lazy" />

            <img class="demo-img demo-img__mobile" :src="businessObservabilityMobile" loading="lazy" />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Columbus AI - Metis -->
    <div class="cronus-ai">
      <v-container full-height justify>
        <v-row class="mt-10" align="center">
          <v-col cols="12" md="6" class="ai-text">
            <h2>Columbus AI<br> & Business Observability</h2>

            <p>Intelligent Decision Making with AI & Insights</p>

            <img :src="cronusAiMetisIcon" class="mt-5" />

            <p class="title-description mt-3">
              AI-powered Strategy Execution drives organisational performance by integrating AI and surfacing insights for informed planning, adaptive execution, 
              and automated reporting, all while enhancing decision-making and predictability.
            </p>
          </v-col>
          <v-col cols="12" md="1"></v-col>
          <v-col cols="12" md="5">
              <img class="demo-img" :src="aiInsights" loading="lazy" />
          </v-col>
        </v-row>
      </v-container>
      
    </div>

    <!-- What makes us unique section -->
    <div class="about-us">
      <v-container>
        <h2>What makes us unique</h2>

        <v-row class="mt-10">
          <v-col cols="12" md="4">
            <v-card class="elevation-2">
              <v-card-text>
                <h3>
                  Unifying AI, Business Observability, OKRs, and KPIs in a
                  single platform
                </h3>

                <p class="mt-3">Integrating AI with strategic indicators.</p>

                <p class="mt-3">
                  Connecting strategic goals with operational metrics and
                  intelligent real-time data, enabling quick responses to
                  trends, all within a single, streamlined platform
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card class="elevation-2">
              <v-card-text>
                <h3>Developed with Leadership and Executives in mind</h3>

                <p class="mt-3">
                  Uniquely designed for Leadership and Executives, offering a
                  rolled-up view & insights of organisational performance,
                  enabling swift and effective decision making
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card class="elevation-2">
              <v-card-text>
                <h3>
                  Deep & personalised consultancy expertise, enabling successful
                  OKRs adoption & value realisation
                </h3>

                <p class="mt-3">
                  Focusing on understanding your strategy & distinct
                  requirements.
                </p>

                <p class="mt-3">
                  Preparing leadership and teams for effective integration of
                  OKRs, ensuring their journey towards successful adoption
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <video class="bg-video" preload="none" autoplay loop muted playsinline disablePictureInPicture :poster="aboutUsBgPoster">
        <source :src="aboutUsBg" type="video/mp4" />
      </video>
    </div>

    <!-- Resource Hub section -->
    <div class="resource-hub">
      <v-container>
        <v-row>
          <v-col cols="12" class="d-flex align-center flex-wrap justify-space-between mb-3">
            <h2>Resource Hub</h2>

            <router-link to="/resources"><v-btn class="btn-border" text>See all</v-btn></router-link>
          </v-col>
        </v-row>

        <v-row class="resource-hub__links">
          <v-col cols="12" md="4">
            <router-link to="/article/democratisation-of-corporate-decision-making"
              class="d-flex justify-space-between w-100">
              <v-card>
                <v-card-text>
                  <div class="title-text mt-3">
                    Democratisation of corporate decision-making: navigating the balance
                  </div>
                </v-card-text>

                <v-card-actions>
                  <div class="d-flex justify-space-between w-100">Find out more
                    <RightArrow color="#0D0D0D" />
                  </div>
                </v-card-actions>
              </v-card>
            </router-link>
          </v-col>

          <v-col cols="12" md="4">
            <router-link to="/article/driving-innovation-through-collaboration">
              <v-card>
                <v-card-text>
                  <div class="title-text mt-3">
                    Driving innovation through collaboration
                  </div>
                </v-card-text>

                <v-card-actions>
                  <div class="d-flex justify-space-between w-100">Find out more
                    <RightArrow color="#0D0D0D" />
                  </div>
                </v-card-actions>
              </v-card>
            </router-link>
          </v-col>

          <v-col cols="12" md="4">
            <router-link to="/article/making-better-leadership-decisions-with-ai">
              <v-card>
                <v-card-text>
                  <div class="title-text mt-3">
                    Making Better Leadership Decisions with AI
                  </div>
                </v-card-text>

                <v-card-actions>
                  <div class="d-flex justify-space-between w-100">Find out more
                    <RightArrow color="#0D0D0D" />
                  </div>
                </v-card-actions>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <FooterSite />
  </div>
</template>

<script>
import { eventBus } from '@/event-bus';
import { videoMixin } from '@/plugins/videoMixin';
import HeaderSite from "@/components/Header";
import FooterSite from "@/components/Footer";
import RightArrow from "@/icons/RightArrow";

export default {
  name: "Main",
  mixins: [videoMixin],
  components: {
    HeaderSite,
    FooterSite,
    RightArrow
  },
  data() {
    return {
      heroVideo: require("@/assets/video/hero-video.mp4"),

      strategyExecutionIcon: require("@/assets/img/strategy-execution-icon.png"),
      strategyExecutionNew: require("@/assets/img/strategy-execution-new.png"),
      // strategyExecutionMobile: require("@/assets/img/strategy-execution-mobile.png"),

      businessObservabilityIcon: require("@/assets/img/icons/business-observability-icon.png"),
      businessObservabilityDemo: require("@/assets/img/business-observability-demo.png"),
      businessObservabilityMobile: require("@/assets/img/business-observability-mobile.png"),
      
      aiInsights: require("@/assets/img/ai-insights.png"),

      cronusAiMetisIcon: require("@/assets/img/cronus-ai-metis.png"),
      cronusAiBg: require("@/assets/video/cronus-ai-bg.mp4"),
      aboutUsBg: require("@/assets/video/about-us-bg.mp4"),

      // Video Poster
      heroVideoPoster: require("@/assets/video/poster/hero-video-poster.jpg"),
      cronusAiBgPoster: require("@/assets/video/poster/cronus-ai-poster.jpg"),
      aboutUsBgPoster: require("@/assets/video/poster/about-us-poster.jpg"),
    };
  },
  methods: {
    scrollToNextBlock() {
      const nextBlock = document.querySelector('.strategy-execution');

      if (nextBlock) {
        nextBlock.scrollIntoView({ behavior: 'smooth' });
      }
    },
    openGetInTouchModal(value) {
      eventBus.value.$emit('openFormModal', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-section {
  min-height: 100vh;

  .hero-content {
    width: 50vw;
    min-width: 600px;
    margin-left: 20px;
    position: relative;
    z-index: 10;
    padding: 64px 0 50px;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: flex-end;
      min-width: unset;
      width: calc(100% - 40px);
      padding: 84px 0 160px;
    }

    h1 {
      font-weight: 475;
      font-size: 64px;
      font-family: "Campton";
      line-height: normal;
      text-shadow: 0 0 5px #00000073;

      @media screen and (max-width: 768px) {
        font-size: 36px;
      }
    }

    button {
      @media screen and (max-width: 768px) {
        padding: 0 8px;
      }
    }

    .contact-btn {
      background: transparent;
      border: 1px solid #FFF;
      margin-left: 20px;
      text-transform: unset;

      @media screen and (max-width: 768px) {
        margin-left: 10px;
      }
    }
  }

  .scroll-block {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
    bottom: 0;
    right: 0;
    z-index: 10;
    border-radius: 3px;
    width: 720px;
    max-width: 100%;
    font-family: "Recoleta";
    font-size: 25px;
    border-top: 1px solid var(--Cronus-Dark-Blue-03, #22262e);
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(45px);
    border-top-left-radius: 12px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      font-size: 18px;
      padding: 15px;
      border-top-left-radius: 0;
    }

    &__arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 68px;
      height: 68px;
      min-width: 68px;
      border-radius: 50%;
      border: 1px solid #6F7680;
      margin-left: 10px;
      cursor: pointer;
      background: transparent;
      transition: 350ms;

      &:hover {
        background: #b8b8b838;
      }

      @media screen and (max-width: 768px) {
        width: 50px;
        height: 50px;
        min-width: 50px;
      }
    }
  }

  .parallax-video {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    background: #0d0d0d;

    @media screen and (max-width: 768px) {
      align-items: unset;
    }

    video {
      position: absolute;
      right: -5%;
      bottom: 0;
      height: 120%;

      @media screen and (max-width: 768px) {
        top: 0;
        right: -10%;
        bottom: unset;
        height: 90%;
      }
    }

    .bg-circle {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 10;
      width: 338px;
      height: 238px;
      background: #0442bf;
      filter: blur(250px);
    }

    .bg-opacity {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      z-index: 4;
      background: #0000001c;

      @media screen and (max-width: 768px) {
        background: #00000066;
      }
    }
  }
}

.strategy-execution,
.business-observability {
  position: relative;
  padding: 88px 0 80px 0;
  background: #0d0d0d;
  overflow: hidden;

  h2 {
    font-family: "Recoleta";
    font-size: 44px;
    text-align: center;
    font-weight: 400;
  }

  .title-description {
    text-align: center;
    margin: 0 auto;
  }

  .demo-img__mobile {
    display: none;
  }

  .demo-img {
    position: relative;
    right: 0;
    border-radius: 9px;
    margin-left: auto;
    width: 100%;
    filter: drop-shadow(2px 4px 12px #8800a470);

    @media screen and (max-width: 768px) {
      display: none;
      position: relative;
      margin-right: 0;

      &.demo-img__mobile {
        display: block;
      }
    }
  }
}

.pr-md-0 {
  @media screen and (max-width: 768px) {
    padding-right: 0;
  }
}

.business-observability {
  background: #b7dfbd;
  color: #0d0d0d;

  .demo-img {
    border-radius: 9px;
    min-width: unset;
    margin-left: auto;
    margin-right: 0;
    filter: none;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.cronus-ai {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 30vh;
  background: #0d0d0d;
  overflow: hidden;

  .v-container {
    position: relative;
    z-index: 10;
  }

  h2 {
    font-family: "Recoleta";
    font-size: 44px;
  }

  .bg-video {
    position: absolute;
    right: -15%;
    bottom: 0;
    height: 100%;
    max-width: 80%;
    width: 80%;

    @media screen and (max-width: 768px) {
      right: 0;
      max-width: 100%;
      width: 100%;
      opacity: 0.6;
    }
  }
}

.about-us {
  position: relative;
  min-height: 100vh;
  color: #0d0d0d;
  font-weight: 400;
  background: #b7dfbd;
  overflow: hidden;

  .v-container {
    position: relative;
    z-index: 10;
  }

  h2 {
    font-family: "Recoleta";
    font-size: 64px;
    text-align: center;
    margin-top: 40px;
    font-weight: 400;

    @media screen and (max-width: 991px) {
      font-size: 44px;
    }
  }

  .v-card {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    height: 100%;
    border: 1px solid #aac5af;
    background: #b7dfbd;
    box-shadow: none !important;

    h3 {
      font-family: "Recoleta";
      font-size: 32px;
      font-weight: 400;
      line-height: normal;

      @media screen and (max-width: 991px) {
        font-size: 22px;
      }
    }

    p {
      font-size: 20px;
      line-height: 28px;
      color: #303030;

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }

    .v-card-actions,
    .v-card-text {
      color: #0d0d0d;
    }
  }

  .bg-video {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15%;
    max-width: 100%;
    width: 100%;

    @media screen and (max-width: 768px) {
      bottom: 0;
    }
  }
}

.resource-hub {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  background: #f5eaef;
  color: #22262e;
  padding: 50px 0;

  h2 {
    font-family: "Recoleta";
    font-size: 44px;
    font-weight: 400;
  }

  &__links {
    .v-card {
      display: flex;
      align-content: space-between;
      flex-wrap: wrap;
      color: #0d0d0d;
      border: 0.5px solid #cedee4;
      box-shadow: 0 0 20px #00000012;
      border-radius: 6px;
      background: #fff;
      height: 100%;

      .v-card-actions {
        width: 100%;
        padding-left: 1rem;
        border-top: 1px solid #00000029;
      }

      &:hover {
        .title-text {
          color: #0442BF;
        }
      }

      .title-text {
        font-family: "Recoleta";
        font-size: 22px;
        line-height: normal;
        transition: 350ms;
      }
    }
  }
}

.custom-app-bar {
  background: linear-gradient(180deg, black, transparent);
}

.full-height {
  min-height: 100vh;
}</style>

<template>
  <div class="main-section">
    <HeaderSite />

    <!-- Hero section -->
    <div class="page-hero-section">
      <div class="page-hero-section__title">
        <h1>Pricing</h1>
        <p>Move strategy beyond the boardroom to unlock your utmost potential. Developed for leadership, embraced by
          collaborative teams.</p>
      </div>

      <video class="bg-video" autoplay loop muted playsinline disablePictureInPicture :poster="heroBgPoster" @click="toggleVideoPlayback" id="videoBg">
        <source :src="heroBg" type="video/mp4" />
      </video>
    </div>

    <!-- Pricing section -->
    <div class="pricing-section">
      <v-container>
        <v-row class="mt-10">
          <v-col cols="12" md="4">
            <v-card>
              <div class="card-header">
                <h3 class="essential">Core</h3>

                <div class="price">9<span>$</span></div>
                <div class="price-desc">per user/per month</div>

                <p class="mt-3">
                  Essentials for successful OKR implementation and tracking in your organisation
                </p>

                <v-btn class="btn-primary" @click="openGetInTouchModal('demo')">Schedule demo</v-btn>
              </div>

              <div class="card-content">
                <ul class="card-list included">
                  <li>Centralising your Strategy Management & Execution</li>
                  <li>Embarking on OKR journey</li>
                  <li>OKR Setting & Tracking</li>
                  <li>Aligning Strategy, OKR & Execution</li>
                  <li>Introducing dynamic Performance Management</li>
                  <li>Gaining Visibility</li>
                </ul>

                <!-- <ul class="card-list not-included">
                  <li>Backlog</li>
                  <li>Reflect & Reset</li>
                  <li>Customizable Review Builder</li>
                  <li>Basic Task Tracking</li>
                </ul> -->
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card>
              <div class="card-header">
                <h3 class="scale">Scale</h3>

                <div class="most-popular">Most popular</div>

                <div class="price">26<span>$</span></div>
                <div class="price-desc">per user/per month</div>

                <p class="mt-3">
                  For organisations with well-established practices who are looking for a complete strategy execution
                  solution with OKRs at the foundation
                </p>

                <v-btn class="btn-primary" @click="openGetInTouchModal('demo')">Schedule demo</v-btn>
              </div>

              <div class="card-content">
                <ul class="card-list included">
                  <li>Getting Strategy Management & Execution streamlined & bi-directional</li>
                  <li>Executing Strategy with OKR at the foundation</li>
                  <li>Rolling out OKR to multiple teams</li>
                  <li>Aligning Strategy, KPI, OKR, Execution & Initiatives</li>
                  <li>Using Performance Management module</li>
                  <li>Visualising rolled-up dashboards </li>
                </ul>

                <!-- <ul class="card-list not-included">
                  <li>Basic Task Tracking</li>
                </ul> -->
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card>
              <div class="card-header">
                <h3 class="enterprise">Enterprise</h3>

                <div class="talk-text">Let’s talk!</div>

                <p class="mt-3">
                  For more sizable teams and organisations requiring enhanced features, robust security measures, admin
                  controls, and dedicated support
                </p>

                <v-btn class="btn-primary" @click="openGetInTouchModal('contact')">Get a quote</v-btn>
              </div>

              <div class="card-content">
                <ul class="card-list included">
                  <li>E2E Strategy Execution Management</li>
                  <li>Rolling out OKR Across Organisation or sizeable teams</li>
                  <li>Aligning complex & multifaceted organisation</li>
                  <li>Using full-stack Strategy Execution Platform</li>
                  <li>AI Powered Insights & Decision Making</li>
                  <li>Establishing Business Observability</li>
                  <li>Customised dashboards & visualisation</li>
                </ul>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Review section-->
    <UserReview />

    <FooterSite />
  </div>
</template>

<script>
import { eventBus } from '@/event-bus';
import { videoMixin } from '@/plugins/videoMixin';
import HeaderSite from "@/components/Header";
import FooterSite from "@/components/Footer";
import UserReview from "@/components/UserReview";

export default {
  name: "Pricing",
  mixins: [videoMixin],
  components: {
    HeaderSite,
    FooterSite,
    UserReview
  },
  data() {
    return {
      heroBg: require("@/assets/video/cronus-ai-bg.mp4"),
      heroBgPoster: require("@/assets/video/poster/cronus-ai-poster.jpg"),
    };
  },
  head() {
    return {
      title: "Pricing",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Description of your AI-Powered Strategy Execution Platform.",
        },
      ],
    };
  },
  methods: {
    openGetInTouchModal(value) {
      eventBus.value.$emit('openFormModal', value);
    }
  }
};
</script>

<style lang="scss" scoped>
.pricing-section {
  background: #F1F1F1;
  padding-bottom: 70px;

  .v-card {
    position: relative;
    background: #FFF;
    color: #0D0D0D;
    box-shadow: 0 0 30px #00000014;
    height: 100%;

    .card-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 24px;
      text-align: center;
      min-height: 300px;
      border-bottom: 1px solid #CDCDCD;

      .price {
        width: 100%;
        color: #0D0D0D;
        font-size: 40px;
        font-weight: bold;

        span {
          font-size: 20px;
          font-weight: bold;
        }
      }

      .price-desc {
        font-size: 16px;
        width: 100%;
        color: #6F7680;
      }

      h3 {
        font-weight: 400;

        &.essential {
          color: #8665F8;
        }

        &.scale {
          color: #D94B4B;
        }

        &.enterprise {
          color: #ED7A24;
        }
      }

      p {
        opacity: 0.5;
        font-size: 14px;
        line-height: normal;
      }

      .talk-text {
        width: 100%;
        font-size: 35px;
        font-weight: bold;
      }

      .most-popular {
        position: absolute;
        top: 10px;
        right: 10px;
        background: #94E0D4;
        border-radius: 30px;
        padding: 6px 12px;
        margin-left: 5px;
        font-size: 12px;
        height: fit-content;
      }
    }

    .card-content {
      min-height: 380px;
      padding: 30px;
      padding-top: 24px;
      font-size: 14px;

      .card-list {
        list-style-type: none;
        // list-style-position: inside;

        &.included {
          // list-style-image: url('@/assets/img/icons/check-circle.png');
        }

        &.not-included {
          // list-style-image: url('@/assets/img/icons/x-circle.png');
        }

        li {
          /* Add some left padding to make space for the image */
          background-image: url('@/assets/img/icons/check-circle.png');
          /* Set your custom image as a background image */
          background-position: left center;
          /* Center the background image vertically and horizontally */
          background-repeat: no-repeat;
          /* Prevent image repetition */
          padding-left: 40px;
          /* Adjust this value based on your image size and padding */
          margin-bottom: 14px;
        }
      }
    }
  }
}

.review-section {
  border-bottom: 1px solid #CDCDCD;
}
</style>

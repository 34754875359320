<template>
  <div class="consultancy-section">
    <HeaderSite />

    <!-- Hero section -->
    <div class="page-hero-section">
      <div class="page-hero-section__title">
        <h1>Consulting</h1>
        <p>We set up organisations for success from the onset. This is what makes Columbus different. </p>

        <p>We combine ambitious goal-setting with structured strategies, enhanced by the power of OKRs & Business
          Observability. We are more than just a tech platform; we are your strategic advisors, here to guide and support
          every step of the way.</p>
      </div> 

      <video class="bg-video" autoplay loop muted playsinline disablePictureInPicture :poster="heroBgPoster">
        <source :src="heroBg" type="video/mp4" />
      </video>
    </div>

    <!-- About Consulting -->
    <div class="about-consulting">
      <v-container>
        <h2>Our OKR and Management Consulting Services</h2>

        <v-row class="mt-10">
          <v-col cols="12" md="4">
            <img :src="businessObservabilityIcon" />
            <h3>OKR Transformation Advisory</h3>
            <p>
              Introducing a goal-setting, it is about reshaping your organisational mindset. We dive deep into your
              current strategies, processes, and objectives to integrate the OKR framework. We support you at every stage,
              ensuring that the transition is smooth, stakeholders are aligned, and the system adopted amplifies clarity,
              agility, and measurable success.
            </p>
          </v-col>

          <v-col cols="12" md="4">
            <img :src="performanceManagementIcon" style="opacity: 0.8;" />
            <h3>Performance Management</h3>
            <p>
              We support organisations in fine-tuning their operating rhythm, promoting a culture of continual
              improvement, and ensuring an alignment between strategy and action. Unlock the full potential of your team,
              ensuring that every step taken is a stride towards achieving your strategic imperatives.
            </p>
          </v-col>

          <v-col cols="12" md="4">
            <img :src="roadIcon" />
            <h3>Ways of Working</h3>
            <p>
              Our Change Management Support strengthens team dynamics and embeds a strategic mindset within your
              organisational culture. We guide teams to adapt to new ways of working, ensuring they are well-positioned to
              harness new opportunities.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Review section-->
    <UserReview />

    <!-- Consluting team -->
    <div class="consluting-team">
      <v-container>
        <h2>Our consulting team</h2>

        <v-row class="mt-10">
          <v-col cols="6" md="3">
            <div><img class="avatar-image" :src="teamAvatar1" /></div>
            <div class="team-name">Kat Gref</div>
            <div class="team-position">Head of Consulting</div>
            <a href="https://www.linkedin.com/in/kat-gref-2401784b/" target="_blank" class="social-link"><img
                :src="linkedinIcon" /></a>
          </v-col>

          <v-col cols="6" md="3">
            <div><img class="avatar-image" :src="teamAvatar2" /></div>
            <div class="team-name">Rida Qureshi</div>
            <div class="team-position">Transformation & OKR Expert</div>
            <a href="https://www.linkedin.com/in/rida-qureshi-48574a171/" target="_blank" class="social-link"><img
                :src="linkedinIcon" /></a>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <FooterSite />
  </div>
</template>

<script>
import { videoMixin } from '@/plugins/videoMixin';
import HeaderSite from "@/components/Header";
import FooterSite from "@/components/Footer";
import UserReview from "@/components/UserReview";

export default {
  name: "Consultancy",
  mixins: [videoMixin],
  components: {
    HeaderSite,
    FooterSite,
    UserReview
  },
  data() {
    return {
      heroBg: require("@/assets/video/cronus-ai-bg.mp4"),
      heroBgPoster: require("@/assets/video/poster/cronus-ai-poster.jpg"),
      businessObservabilityIcon: require("@/assets/img/icons/business-observability-icon.png"),
      performanceManagementIcon: require("@/assets/img/icons/performance-management-icon.png"),
      roadIcon: require("@/assets/img/icons/road-icon.png"),
      teamAvatar1: require("@/assets/img/team/2.jpg"),
      teamAvatar2: require("@/assets/img/team/5.jpg"),

      reviewAvatar: require("@/assets/img/quote-avatar.jpg"),
      linkedinIcon: require("@/assets/img/icons/linkedin.svg"),
    };
  },
  head() {
    return {
      title: "Consultancy",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Description of your AI-Powered Strategy Execution Platform.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.consultancy-section {
  .about-consulting {
    background: #B7DFBD;
    color: #0D0D0D;
    padding: 40px 0;

    h3 {
      font-family: 'Recoleta';
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 15px;
    }

    img {
      width: 70px;
    }
  }

  .consluting-team {
    background: #F1F1F1;
    color: #0D0D0D;
    padding: 70px 0;

    .team-name {
      font-family: 'Recoleta';
      font-size: 24px;
      font-weight: bold;
    }

    .social-link {
      width: 45px;
      height: 45px;
      margin-left: 0;
      margin-top: 10px;
    }

    .avatar-image {
      max-width: 90px;
      border-radius: 50%;

      @media screen and (max-width: 768px) {
        max-width: 80px;
      }
    }
  }
}
</style>

<template>
  <section class="doc-video-section" >
    <video class="info-video" controls controlsList="nodownload">
      <source :src="videoSrc" type="video/mp4" />
    </video>
  </section>
</template>
<script>
export default {
  name: "VideoContent",
  props: {
    videoSrc: String
  },
};
</script>
<style lang="scss" scoped>
.doc-video-section {
  background-color: #fff;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .info-video {
    height: calc(100vh - 64px);
    max-width: 100%;
    @media screen and (max-width: 768px){
      height: auto;
    }
  }
}
</style>
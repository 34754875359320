import { ref } from 'vue';

export const eventBus = ref(null);

export function setupEventBus() {
  eventBus.value = new (class {
    constructor() {
      this.callbacks = {};
    }

    $on(event, callback) {
      if (!this.callbacks[event]) {
        this.callbacks[event] = [];
      }
      this.callbacks[event].push(callback);
    }

    $emit(event, ...args) {
      if (this.callbacks[event]) {
        this.callbacks[event].forEach((callback) => callback(...args));
      }
    }

    $off(event, callback) {
      if (this.callbacks[event]) {
        const index = this.callbacks[event].indexOf(callback);
        if (index !== -1) {
          this.callbacks[event].splice(index, 1);
        }
      }
    }
  })();
}

setupEventBus();
<template>
  <div class="main-section">
    <HeaderSite />

    <!-- Hero section -->
    <div class="hero-section">
      <button type="button" class="go-back-btn" @click.stop="windowHistory.go(-1)"><v-icon>mdi-arrow-left</v-icon>Go Back</button>
    </div>

    <!-- Content section -->

    <div class="content-section">
      <div class="content-section__box">
        <h1>Terms and conditions</h1>

        <p>You (including any legal entity by which You are employed or representing, collectively "You" or "User") and Columbus Technologies Limited. ("Columbus Tech," "Company," "we," or "us"), and, where explicitly stated, our affiliated entities</p>

        <p>Please review these terms carefully before using our services.</p>

        <p>By accessing or using the service, You acknowledge and agree to all the terms and conditions outlined in these Terms. These terms pertain to the use of our online and mobile business management and analytics services, website, and associated platforms, including mobile websites, owned and operated by us, our predecessors or successors, or our Affiliates (collectively, the "Site"); the downloadable application (the "Application" or "App"); all services, applications, and other products provided by Columbus (collectively "Service" or "Services") whether directly provided by us or our Affiliates.</p>

        <p>These Terms incorporate and refer to the Columbus Privacy Policy (including the Columbus Cookie Policy) as they may be in effect and amended by Columbus from time to time (together with this Agreement, the "Agreements"). If You have any inquiries regarding this Agreement, please contact us at: sales@columbus.tech.</p>

        <p>Modifications to the Terms:</p>

        <p>Columbus retains the sole authority to revise this Agreement and any other policies encompassed in it at any time. Changes will be communicated through posting a revised version on the Site or within the App.</p>

        <p>All new or modified Terms are effective immediately, and Your continued use of the Service post-modification indicates Your agreement to the latest version of this Agreement. In case of substantial changes or modifications affecting Your rights, we will notify You by prominently displaying notice of such changes on the Site or sending them to the email address You provided during registration.</p>

        <p>If You dissent from any alterations, You can choose to close Your account.</p>

        <p>We advise regular review of the Site for the most up-to-date information on our Terms.</p>

        <p>To ensure our ability to communicate with You electronically, You are required to promptly inform us of any changes to Your email address by updating Your Account information on the Site or by contacting User Support.</p>

        <p>DEFINITIONS.</p>

        <p>In this Agreement, the following terms carry the designated meanings:</p>

        <p>"Affiliate" refers to an entity that, from time to time, directly or indirectly, exercises control over, is controlled by, or shares common control with a party. Additionally, any other entity recognized in writing by the parties as an Affiliate qualifies under this definition.</p>

        <p>"Authorized Users" signifies directors or employees of (i) the User, (ii) the User's Affiliates (not being Competitors), or (iii) based on the stipulations of this Agreement, individual contractors of the User or the User's Affiliates (not being, or being employed by, Competitors) or third-party suppliers (not being Competitors).</p>

        <p>These individuals are entitled to access and use the Services as per permissions granted by the User.</p>

        <p>"Change Request" pertains to a written proposal submitted by the Customer to Columbus in accordance with Section 9, outlining desired changes or modifications to the Services.</p>

        <p>"Competitive Purposes" encompasses all activities related to the development, design, sale, marketing, or support of business analytics products, software, or services, except for providing feedback about the Service to Columbus.</p>

        <p>"Confidential Information" encompasses any information or data, regardless of form or storage medium, tangible or intangible, disclosed directly or indirectly, before or after this Agreement, by the Discloser to the Recipient. It includes trade secrets, designs, specifications, products or services (including the Services), inventions, algorithms, software, computer models, processes, customer lists, technical and business information, and other sensitive information, whether intrinsically confidential or due to the context in which it was disclosed.</p>

        <p>"Content" refers to data or information displayed, transmitted, generated, or stored by You through the Service.</p>

        <p>The agreement employs the terms "data controller," "data processor," "personal data," "processing," and "appropriate technical and organizational measures" in accordance with the General Data Protection Regulation of the European Union or relevant Data Protection Legislation.</p>

        <p>"Data Protection Legislation" includes the General Data Protection Regulation of the European Union, European Directive 2002/58/EC, and associated legislation and regulations, as well as applicable laws relating to processing personal data and privacy.</p>

        <p>"Documentation" includes online documentation and related media provided by Columbus describing the Service and its usage.</p>

        <p>"Columbus Technology" pertains to all proprietary technology (including software, hardware, products, processes, algorithms, user interfaces, know-how, designs, and other technical material or information) provided by Columbus to facilitate the Service.</p>

      </div>
    </div>

    <FooterSite />
  </div>
</template>

<script>
import HeaderSite from "@/components/Header";
import FooterSite from "@/components/Footer";

export default {
  name: "TermsAndConditions",
  components: {
    HeaderSite,
    FooterSite
  },
  data() {
    return {
      windowHistory: window.history
    };
  },
  head() {
    return {
      title: "Terms and Conditions",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Description of your AI-Powered Strategy Execution Platform.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.hero-section {
  height: 512px;
  max-height: 80vh;
  background: #CDCDCD;
  position: relative;
  z-index: 1;
}
.go-back-btn {
  position: absolute;
  left: 16px;
  bottom: calc(35vh + 30px);
  color: #0442bf;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: color .3s ease-in-out;
  &:hover {
    color: #002e8a;
  }
  .v-icon {
    margin-right: 8px;
  }
}
.content-section {
  position: relative;
  z-index: 2;
  background: #F1F1F1;
  color: #22262E;
  padding-bottom: 50px;
  display: flex;

  &__box {
    display: inline-block;
    width: 90%;
    max-width: 870px;
    background: #FFFFFF;
    padding: 24px;
    margin: -35vh auto 0;
    border-radius: 6px;
    box-shadow: 0 0 20px #00000017;
  }
}
</style>

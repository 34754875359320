import { createRouter, createWebHistory } from "vue-router";
import Main from "./pages/Main";
import Consultancy from "./pages/Consultancy";
import Pricing from "./pages/Pricing";
import AboutUs from "./pages/AboutUs";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ErrorPage404 from "./pages/ErrorPage404";
import Resources from "./pages/Resources";
import Articles from "./pages/Articles";
import Help from "./pages/Help";

const routes = [
  {
    path: "/",
    component: Main,
    meta: {
      title: "Next Gen AI-Powered Strategy Execution Platform​ | Columbus",
      metaTags: [
        {
          name: "robots",
          content: "index, follow"
        },
        {
          name: "description",
          content: "Ambitious Goals and Clear Strategy: Align, Focus, Measure with OKRs & Business Observability. Align Your Goals and Strategy with OKRs"
        },
        {
          property: "og:description",
          content: "Ambitious Goals and Clear Strategy: Align, Focus, Measure with OKRs & Business Observability. Align Your Goals and Strategy with OKRs"
        },
        {
          property: "og:site_name",
          content: "Columbus"
        },
      ]
    }
  },
  {
    path: "/consultancy",
    component: Consultancy,
    meta: {
      title: "Consulting Services​ | Columbus",
      metaTags: [
        {
          name: "robots",
          content: "index, follow"
        },
        {
          name: "description",
          content: "Columbus assist in establishing lasting business achievement, where your prosperity stands as our ultimate benchmark for performance."
        },
        {
          property: "og:description",
          content: "Columbus assist in establishing lasting business achievement, where your prosperity stands as our ultimate benchmark for performance."
        },
        {
          property: "og:site_name",
          content: "Columbus"
        },
      ]
    }
  },
  {
    path: "/pricing",
    component: Pricing,
    meta: {
      title: "Pricing License | Columbus",
      metaTags: [
        {
          name: "robots",
          content: "index, follow"
        },
        {
          name: "description",
          content: "Find out the prices for our platform licenses. Unlock the full potential of your business goals with our comprehensive OKR management tool."
        },
        {
          property: "og:description",
          content: "Find out the prices for our platform licenses. Unlock the full potential of your business goals with our comprehensive OKR management tool."
        },
        {
          property: "og:site_name",
          content: "Columbus"
        },
      ]
    }
  },
  {
    path: "/about-us",
    component: AboutUs,
    meta: {
      title: "About Us | Columbus",
      metaTags: [
        {
          name: "robots",
          content: "index, follow"
        },
        {
          name: "description",
          content: "Columbus help organizations of all sizes unlock their full potential. How? By harnessing the strength of strategic agility to forge a competitive advantage."
        },
        {
          property: "og:description",
          content: "Columbus help organizations of all sizes unlock their full potential. How? By harnessing the strength of strategic agility to forge a competitive advantage."
        },
        {
          property: "og:site_name",
          content: "Columbus"
        },
      ]
    }
  },
  {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
    meta: {
      title: "Terms and conditions | Columbus",
      metaTags: [
        {
          name: "robots",
          content: "index, follow"
        },
        {
          name: "description",
          content: "Read our Terms and Conditions to understand the rules and policies governing your use of our services. Learn about your rights, obligations, and privacy."
        },
        {
          property: "og:description",
          content: "Read our Terms and Conditions to understand the rules and policies governing your use of our services. Learn about your rights, obligations, and privacy."
        },
        {
          property: "og:site_name",
          content: "Columbus"
        },
      ]
    }
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    meta: {
      title: "Privacy Policy | Columbus",
      metaTags: [
        {
          name: "robots",
          content: "index, follow"
        },
        {
          name: "description",
          content: "We use cookies and similar tracking technologies to track the activity on our Website and hold certain information."
        },
        {
          property: "og:description",
          content: "We use cookies and similar tracking technologies to track the activity on our Website and hold certain information."
        },
        {
          property: "og:site_name",
          content: "Columbus"
        },
      ]
    }
  },
  {
    path: "/404",
    component: ErrorPage404,
    meta: {
      title: "404 Error Page | Columbus",
      metaTags: [
        {
          name: "robots",
          content: "index, follow"
        },
        {
          name: "description",
          content: "404 Error Page: Navigate Back to Our Homepage or Explore Our Other Pages for What You're Looking For."
        },
        {
          property: "og:description",
          content: "404 Error Page: Navigate Back to Our Homepage or Explore Our Other Pages for What You're Looking For."
        },
        {
          property: "og:site_name",
          content: "Columbus"
        },
      ]
    }
  },
  {
    path: "/resources",
    component: Resources,
    meta: {
      title: "Resources | Columbus",
      metaTags: [
        {
          name: "robots",
          content: "index, follow"
        },
        {
          name: "description",
          content: "Unlock the full potential of your business goals with our comprehensive OKR management tool. Links to blog pages."
        },
        {
          property: "og:description",
          content: "Unlock the full potential of your business goals with our comprehensive OKR management tool. Links to blog pages."
        },
        {
          property: "og:site_name",
          content: "Columbus"
        },
      ]
    }
  },
  {
    path: '/article/:name',
    component: Articles,
    meta: {
      metaTags: [
        {
          name: "robots",
          content: "index, follow"
        },
        {
          property: "og:site_name",
          content: "Columbus"
        },
      ]
    }
  },
  {
    path: "/help",
    component: Help,
    meta: {
      title: "OKR Training module | Columbus",
      metaTags: [
        {
          name: "robots",
          content: "index, follow"
        },
        {
          name: "description",
          content: "OKR Training module"
        },
        {
          property: "og:description",
          content: "OKR Training module"
        },
        {
          property: "og:site_name",
          content: "Columbus"
        },
      ]
    }
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  const metaTags = to.meta.metaTags || [];
  metaTags.forEach(tag => {
    const tagElement = document.querySelector(`meta[${tag.name || "property"}="${tag.property}"]`);
    if (tagElement) {
      tagElement.setAttribute("content", tag.content);
    } else {
      const newTag = document.createElement("meta");
      newTag.setAttribute(tag.name ? "name" : "property", tag.name || tag.property);
      newTag.setAttribute("content", tag.content);
      document.head.appendChild(newTag);
    }
  });

  window.scrollTo(0, 0)

  next();
});

export default router;

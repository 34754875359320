<template>
  <div class="main-section">
    <HeaderSite />

    <div class="content-section">

      <div class="error-image">
        <img class="circle-text" :src="circleText404" />

        <div class="video-error__box">
          <img :src="scaredDog" />
        </div>
      </div>

      <div class="nav-box">
        <h5>We couldn’t find the page you are looking for</h5>

        <div class="nav-box__links">
          <v-btn class="btn-primary" @click="openGetInTouchModal('demo')">Schedule demo</v-btn>
          <router-link to="/" class="home-page-link">Homepage <RightArrow color="#0442BF" /></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/event-bus';
import HeaderSite from "@/components/Header";
import RightArrow from "@/icons/RightArrow";

export default {
  name: "ErrorPage404",
  components: {
    HeaderSite,
    RightArrow
  },
  data() {
    return {
      circleText404: require("@/assets/img/angle.png"),
      scaredDog: require("@/assets/video/scared-dog.gif"),
    };
  },
  head() {
    return {
      title: "ErrorPage404",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Description of your AI-Powered Strategy Execution Platform.",
        },
      ],
    };
  },
  methods: {
    openGetInTouchModal(value) {
      eventBus.value.$emit('openFormModal', value);
    }
  }
};
</script>

<style lang="scss" scoped>
.content-section {
  height: 100vh;
  background: #F1F1F1;

  .error-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 64px;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 768px) {
      position: relative;
      width: 94%;
      padding-top: 94px;
      height: min-content;
      margin: 0 auto;
    }

    .circle-text {
      width: 100%;
      max-width: 624px;
    }

    .video-error__box {
      position: absolute;
      width: 500px;
      height: 500px;
      overflow: hidden;
      border-radius: 50%;

      @media screen and (max-width: 768px) {
        max-width: 80%;
        max-height: 70%;
      }

      img {
        width: 100%;
      }
    }
  }

  .nav-box {
    position: absolute;
    bottom: 32px;
    left: 32px;
    color: #000000;
    max-width: 343px;
    padding: 24px;
    background: #FFF;
    border: 1px solid #cccccc;
    border-radius: 5px;
    box-shadow: 0 0 20px #00000014;

    h5 {
      font-weight: bold;
    }

    &__links {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 16px;
      margin-top: 16px;
      border-top: 1px solid #90B0BB;

      a.home-page-link {
        display: flex;
        margin-left: 10px;
      }
    }
  }
}
</style>
